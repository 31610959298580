import String from '@/presentation/utils/string'

export default class Color {
  // MARK: - Properties

  static black = 'black'
  static blue = 'blue'
  static green = 'green'
  static grey = 'grey'
  static grey = 'grey'
  static main = 'main'
  static red = 'red'
  static secondary = 'secondary'
  static white = 'white'

  // MARK: - Methods

  /**
   * @param {string} value
   * @return {string} classes with color
  */
  static textColor (value) {
    return value + String.textColorSuffix
  }
}
