<template>
  <div>
    <!-- Content -->
    <custom-row :view-model="viewModel.contentRow">
      <custom-col :view-model="viewModel.contentCol">

        <!-- Title -->
        <custom-section-title :view-model="viewModel.title"/>

        <!-- Card -->
        <custom-row :view-model="viewModel.itemRow">
          <custom-col :view-model="viewModel.itemCol">
            <custom-card :view-model="viewModel.offerCard"/>
          </custom-col>
        </custom-row>

      </custom-col>
    </custom-row>
  </div>
</template>

<script>
import AppPricesViewModel from '@/presentation/scene/app/view-model/appPricesViewModel'
import SectionTitleComponent from '@/app/common/component/section-title/SectionTitleComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import CardComponent from '@/app/scene/app/component/prices/AppPricesOfferCardComponent'

export default {
  name: 'AppPricesComponent',

  components: {
    customSectionTitle: SectionTitleComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customCard: CardComponent
  },

  props: {
    viewModel: {
      type: AppPricesViewModel,
      required: true
    }
  }
}
</script>
