// MARK: - Constants

class Constants {
  static defaultShowContent = false

  static Tab = {
    other: '_blank',
    same: '_self'
  }

  static AssetUrl = {
    images: 'https://www.facebook.com/So-coaching-104910924459582/photos',
    movies: 'https://www.facebook.com/So-coaching-104910924459582/videos'
  }

  static MapUrl = {
    apple: 'https://maps.apple.com/?q=46.567674,-1.717340&sll=46.567674,-1.717340&sspn=0.001735,0.004807',
    google: 'https://goo.gl/maps/ewVeqF9SaMqr2QfX7'
  }

  static CoachSocial = {
    facebook: 'https://www.facebook.com/104910924459582/',
    instagram: 'https://instagram.com/romso_coaching?utm_medium=copy_link'
  }

  static ContactUrl = {
    messenger: 'http://m.me/104910924459582',
    phone_number: 'tel:+33665943380'
  }
}

// MARK: - Class

export default class AppInteractor {
  // MARK: - Properties

  /** @type {AppPresenter} */
  _presenter

  /** @type {boolean} */
  _showNavigationMenu

  // MARK: - Constructor

  /**
   * @constructor
   * @param {AppPresenter} presenter
   */
  constructor (presenter) {
    this._presenter = presenter

    this._showNavigationMenu = Constants.defaultShowContent
  }
  // MARK: - Life cycle vue

  vueMounted () {
    // Update selected item on app bar
    this._presenter.updateSelectedNavigationItemFromInteractor()
  }

  // MARK: From Controller

  menuTappedFromController () {
    this._showNavigationMenu = !this._showNavigationMenu
    this._presenter.showMenuFromInteractor(this._showNavigationMenu)
  }

  /**
   * @param {string} anchor to scroll to correct div
   */
  navigationItemTappedFromController (anchor) {
    // Scroll to specific div
    this._presenter.scrollToAnchorFromInteractor(anchor)

    // Hide menu
    this._showNavigationMenu = false
    this._presenter.showMenuFromInteractor(this._showNavigationMenu)
  }

  closeNavigationTappedFromController () {
    if (this._showNavigationMenu) {
      this._showNavigationMenu = false
    }
  }

  scrollFiredFromController () {
    // Update selected item on app bar
    this._presenter.updateSelectedNavigationItemFromInteractor()
  }

  /**
   * @param {int} index tapped (0: photos, 1: videos)
   */
  assetTappedFromController (index) {
    const url = (index === 0) ? Constants.AssetUrl.images : Constants.AssetUrl.movies
    this._openUrl(url)
  }

  /**
   * @param {int} index tapped (0: apple plan, 1: google maps)
   */
  coachSocialTappedFromController (index) {
    const url = (index === 0) ? Constants.CoachSocial.facebook : Constants.CoachSocial.instagram
    this._openUrl(url)
  }

  /**
   * @param {int} index tapped (0: apple plan, 1: google maps)
   */
  mapTappedFromController (index) {
    const url = (index === 0) ? Constants.MapUrl.apple : Constants.MapUrl.google
    this._openUrl(url)
  }

  /**
   * @param {int} index tapped (0: call phone, 1: send email, 2: Facebook Manager)
   */
  contactLinkTappedFromController (index) {
    // Call phone number in same tab
    if (index === 0) {
      window.open(Constants.ContactUrl.phone_number, Constants.Tab.same)
    } else { // Open messenger on other tab
      this._openUrl(Constants.ContactUrl.messenger)
    }
  }

  // MARK: - Others

  /**
   * @param {string} url to go
   */
  _openUrl (url) {
    window.open(url, Constants.Tab.other)
  }
}
