import CardViewModel from '@/presentation/view-model/card/cardViewModel'

// MARK: - Constants

class Constants {
    static Image = {
      contain: false
    }
}

// MARK: - Class

export default class AppLocalityPictureCardViewModel extends CardViewModel {
  // MARK: - Properties

    /** @type {string|null} */
    _key
    /** @type {ImageViewModel|null} */
    _image

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      super()

      this._key = null
      this._image = null
    }

    // MARK: - Getter

    /** @return {string|null} */
    get key () {
      return this._key
    }

    /** @return {ImageViewModel|null} */
    get image () {
      return this._image
    }

    // MARK: - Setter

    /** @param {string|null} value */
    set key (value) {
      this._key = value
    }

    /** @param {ImageViewModel|null} value */
    set image (value) {
      this._image = value
      this._image.contain = Constants.Image.contain
    }
}
