import AppBarViewModel from '@/presentation/view-model/app-bar/appBarViewModel'
import NavigationViewModel from '@/presentation/view-model/navigation/navigationViewModel'
import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import BodyViewModel from '@/presentation/view-model/body/bodyViewModel'
import AppLogoViewModel from '@/presentation/scene/app/view-model/appLogoViewModel'
import AppLocalityViewModel from '@/presentation/scene/app/view-model/appLocalityViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import AppHiwViewModel from '@/presentation/scene/app/view-model/appHiwViewModel'
import AppWiaViewModel from '@/presentation/scene/app/view-model/appWiaViewModel'
import AppWaylfViewModel from '@/presentation/scene/app/view-model/appWaylfViewModel'
import AppPricesViewModel from '@/presentation/scene/app/view-model/appPricesViewModel'
import AppContactViewModel from '@/presentation/scene/app/view-model/appContactViewModel'
import AppCatchPhraseViewModel from '@/presentation/scene/app/view-model/appCatchPhraseViewModel'
import AppFooterViewModel from '@/presentation/scene/app/view-model/appFooterViewModel'

// MARK: - Constants

class Constants {
  static space = PresentationSpace.BIG
}

// MARK: - Class

export default class AppViewModel {
  // MARK: - Properties

  /** @type {AppBarViewModel} */
  _appBar
  /** @type {NavigationViewModel} */
  _navigationMenu
  /** @type {BodyViewModel} */
  _body
  /** @type {SpaceViewModel} */
  _bodySectionSpace
  /** @type {AppLogoViewModel} */
  _bodyLogo
  /** @type {AppWiaViewModel} */
  _bodyWia
  /** @type {AppWaylfViewModel} */
  _bodyWaylf
  /** @type {AppHiwViewModel} */
  _bodyHiw
  /** @type {AppPricesViewModel} */
  _bodyPrices
  /** @type {AppCatchPhraseViewModel} */
  _bodyCatchPhrase
  /** @type {AppLocalityViewModel} */
  _bodyLocality
  /** @type {AppContactViewModel} */
  _bodyContact
  /** @type {AppFooterViewModel} */
  _bodyFooter

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    const items = PresentationNavigation.all()

    this._appBar = new AppBarViewModel()
    this._appBar.items = items

    this._navigationMenu = new NavigationViewModel()
    this._navigationMenu.items = items

    this._body = new BodyViewModel()
    this._bodySectionSpace = new SpaceViewModel()
    this._bodySectionSpace.space = Constants.space
    this._bodyLogo = new AppLogoViewModel()
    this._bodyWia = new AppWiaViewModel()
    this._bodyWaylf = new AppWaylfViewModel()
    this._bodyHiw = new AppHiwViewModel()

    this._bodyPrices = new AppPricesViewModel()
    this._bodyCatchPhrase = new AppCatchPhraseViewModel()
    this._bodyLocality = new AppLocalityViewModel()
    this._bodyContact = new AppContactViewModel()
    this._bodyFooter = new AppFooterViewModel()
  }

  // MARK: - Getter

  /** @return {AppBarViewModel} */
  get appBar () {
    return this._appBar
  }

  /** @return {NavigationViewModel} */
  get navigationMenu () {
    return this._navigationMenu
  }

  /** @return {BodyViewModel} */
  get body () {
    return this._body
  }

  /** @return {SpaceViewModel} */
  get bodySectionSpace () {
    return this._bodySectionSpace
  }

  /** @return {AppLogoViewModel} */
  get bodyLogo () {
    return this._bodyLogo
  }

  /** @return {AppWiaViewModel} */
  get bodyWia () {
    return this._bodyWia
  }

  /** @return {AppWaylfViewModel} */
  get bodyWaylf () {
    return this._bodyWaylf
  }

  /** @return {AppHiwViewModel} */
  get bodyHiw () {
    return this._bodyHiw
  }

  /** @return {AppPricesViewModel} */
  get bodyPrices () {
    return this._bodyPrices
  }

  /** @return {AppCatchPhraseViewModel} */
  get bodyCatchPhrase () {
    return this._bodyCatchPhrase
  }

  /** @return {AppLocalityViewModel} */
  get bodyLocality () {
    return this._bodyLocality
  }

  /** @return {AppContactViewModel} */
  get bodyContact () {
    return this._bodyContact
  }

  /** @return {AppFooterViewModel} */
  get bodyFooter () {
    return this._bodyFooter
  }
}
