import Color from '@/presentation/utils/color'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
    static selectedIndex = 0
    static classes = String.empty
    static color = Color.main
    static grow = true
    static light = false
}

// MARK: - Class

export default class TabsViewModel {
  // MARK: - Properties

    /** @type {int} */
    _selectedIndex
    /** @type {string} */
    _classes
    /** @type {string} */
    _color
    /** @type {boolean} */
    _grow
    /** @type {boolean} */
    _light
    /** @type {[TabViewModel]} */
    _items

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._selectedIndex = Constants.selectedIndex
      this._classes = Constants.classes
      this._color = Constants.color
      this._grow = Constants.grow
      this._light = Constants.light
      this._items = []
    }

    // MARK: - Getter

    /** @return {int} */
    get selectedIndex () {
      return this._selectedIndex
    }

    /** @return {string} */
    get classes () {
      return this._classes
    }

    /** @return {string} */
    get color () {
      return this._color
    }

    /** @return {boolean} */
    get grow () {
      return this._grow
    }

    /** @return {boolean} */
    get light () {
      return this._light
    }

    /** @return {[TabViewModel]} */
    get items () {
      return this._items
    }

    // MARK: - Setter

    /** @param {int} value */
    set selectedIndex (value) {
      this._selectedIndex = value
    }

    /** @param {string} value */
    set classes (value) {
      this._classes = value
    }

    /** @param {[TabViewModel]} value */
    set items (value) {
      this._items = value
    }
}
