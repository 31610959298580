import AppManager from '@/common/manager/appManager'

// MARK: - Constants

class Constants {
    static elevation = 5
    static mobileElevation = 0
    static isStyleForMobile = false
}

// MARK: - Class

export default class CardViewModel {
  // MARK: - Properties

    /** @type {string|null} */
    _classes
    /** @type {string|null} */
    _mobileClasses
    /** @type {int} */
    _elevation
    /** @type {int} */
    _mobileElevation
    /** @type {boolean} */
    _isStyleForMobile

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._classes = null
      this._mobileClasses = null
      this._elevation = Constants.elevation
      this._mobileElevation = Constants.mobileElevation
      this._isStyleForMobile = Constants.isStyleForMobile
    }

    // MARK: - Getter

    /** @return {boolean} */
    get _isMobileStyle () {
      return this._isStyleForMobile && AppManager.shared.vuetify.breakpoint.xsOnly
    }

    /** @return {string} */
    get classes () {
      return (this._isMobileStyle) ? this._mobileClasses : this._classes
    }

    /** @return {int} */
    get elevation () {
      return (this._isMobileStyle) ? this._mobileElevation : this._elevation
    }

    // MARK: - Setter

    /** @param {string} value */
    set classes (value) {
      this._classes = value
    }

    /** @param {string} value */
    set mobileClasses (value) {
      this._mobileClasses = value
    }

    /** @param {int} value */
    set elevation (value) {
      this._elevation = value
    }

    /** @param {int} value */
    set mobileElevation (value) {
      this._mobileElevation = value
    }

    /** @param {boolean} value */
    set isStyleForMobile (value) {
      this._isStyleForMobile = value
    }
}
