// MARK: - Constants

class Constants {
  static textColorSuffix = '--text'
  static space = ' '
  static empty = ''

  static Linebreak = {
    json: '\n',
    html: '<br/>'
  }
}

// MARK: - Class

export default class String {
  // MARK: - Properties

  /** @type {string} */
  static textColorSuffix = Constants.textColorSuffix
  /** @type {string} */
  static space = Constants.space
  /** @type {string} */
  static empty = Constants.empty
  /** @type {string} */
  static htmlLinebreak = Constants.Linebreak.html

  // MARK: - Other

  /**
   * Check if string is empty
   * @param {string} value
   * @return {boolean}
   **/
  static isEmpty (value) {
    return (!value || value.length === 0)
  }

  /**
   * @param {string} value
   * @return {string}
   **/
  static addHtmllineBreak (value) {
    return value.replaceAll(Constants.Linebreak.json, Constants.Linebreak.html)
  }
}
