<template>
  <div :class="viewModel.classes">
    <!-- Logo -->
    <custom-image :view-model="viewModel.image"/>
  </div>
</template>

<script>
import AppLogoViewModel from '@/presentation/scene/app/view-model/appLogoViewModel'
import ImageComponent from '@/app/common/component/image/ImageComponent'

export default {
  name: 'AppLogoComponent',

  components: {
    customImage: ImageComponent
  },

  props: {
    viewModel: {
      type: AppLogoViewModel,
      required: true
    }
  }
}
</script>
