import CardViewModel from '@/presentation/view-model/card/cardViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import Color from '@/presentation/utils/color'
import LayoutViewModel from '@/presentation/view-model/layout/layoutViewModel'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import Icon from '@/presentation/utils/icon'
import IconButtonViewModel from '@/presentation/view-model/button/iconButtonViewModel'
import IconViewModel from '@/presentation/view-model/icon/iconViewModel'
import PresentationJustify from '@/presentation/enum/presentationJustify'

// MARK: - Constants

class Constants {
    static classes = 'px-0 pt-0 pb-4 ' + Color.black

    static Avatar = {
      height: '100%',
      contain: false
    }

    static space = PresentationSpace.LITTLE

    static SocialRow = {
      justify: PresentationJustify.CENTER
    }

    static SocialCol = {
      cols: 3,
      md: 2
    }

    static socialContentClasses = 'text-center'

    static SocialButtons = {
      icons: [
        Icon.SOCIAL_FACEBOOK,
        Icon.SOCIAL_INSTAGRAM
      ],
      size: 28
    }
}

// MARK: - Class

export default class AppWiaCoachCardViewModel extends CardViewModel {
  // MARK: - Properties

    /** @type {LayoutViewModel|null} */
    _avatarLayout
    /** @type {ImageViewModel|null} */
    _avatar
    /** @type {string|int|null} */
    _avatarClasses
    /** @type {string|int|null} */
    _avatarSize
    /** @type {SpaceViewModel} */
    _avatarBottomSpace
    /** @type {RowViewModel} */
    _socialRow
    /** @type {ColViewModel} */
    _socialCol
    /** @type {string} */
    _socialContentClasses
    /** @type {[IconButtonViewModel]} */
    _socialIconButtons

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      super()

      this._classes = Constants.classes

      this._avatarLayout = new LayoutViewModel()
      this._avatar = null

      this._avatarBottomSpace = new SpaceViewModel()
      this._avatarBottomSpace.space = Constants.space

      this._socialRow = new RowViewModel()
      this._socialRow.justify = Constants.SocialRow.justify

      this._socialCol = new ColViewModel()
      this._socialCol.cols = Constants.SocialCol.cols
      this._socialCol.mdAndGreater = Constants.SocialCol.md

      this._socialContentClasses = Constants.socialContentClasses

      this._socialIconButtons = Constants.SocialButtons.icons.map(e => {
        const icon = new IconViewModel()
        icon.name = e
        icon.size = Constants.SocialButtons.size

        const button = new IconButtonViewModel()
        button.icon = icon
        return button
      })
    }

    // MARK: - Getter

    /** @return {LayoutViewModel|null} */
    get avatarLayout () {
      return this._avatarLayout
    }

    /** @return {ImageViewModel|null} */
    get avatar () {
      return this._avatar
    }

    /** @return {string|int|null} */
    get avatarClasses () {
      return this._avatarClasses
    }

    /** @return {string|int|null} */
    get avatarSize () {
      return this._avatarSize
    }

    /** @return {SpaceViewModel} */
    get avatarBottomSpace () {
      return this._avatarBottomSpace
    }

    /** @return {RowViewModel} */
    get socialRow () {
      return this._socialRow
    }

    /** @return {ColViewModel} */
    get socialCol () {
      return this._socialCol
    }

    /** @return {string} */
    get socialContentClasses () {
      return this._socialContentClasses
    }

    /** @return {[IconButtonViewModel]} */
    get socialIconButtons () {
      return this._socialIconButtons
    }

    // MARK: - Setter

    /** @param {ImageViewModel|null} value */
    set avatar (value) {
      this._avatar = value
      this._avatar.height = Constants.Avatar.height
      this._avatar.contain = Constants.Avatar.contain
    }
}
