import Vue from 'vue'

export const Bus = new Vue()

export class Events {
    static clicked = 'click'

    static menuClicked = 'menuClick'
    static navigationClicked = 'navigationClick'
    static closeNavigationClicked = 'closeNavigationClick'

    static assetClicked = 'assetClick'
    static coachSocialClicked = 'coachSocialClick'
    static mapClicked = 'mapClick'
    static contactLinkClicked = 'contactLinkClick'
    static contactSendClicked = 'contactSendClick'

    static scroll = 'scroll'
}
