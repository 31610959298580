<template>
    <v-app>
      <!-- App Bar -->
      <custom-app-bar
          :view-model="presenter.viewModel.appBar"
          @menuClick="controller.menuTapped()"
          @navigationClick="controller.navigationItemTapped($event)"
      />

      <!-- Navigation -->
      <custom-navigation
          :view-model="presenter.viewModel.navigationMenu"
          @navigationClick="controller.navigationItemTapped($event)"
          @closeNavigationClick="controller.closeNavigationTapped()"
      />

      <!-- Body -->
      <custom-body :view-model="presenter.viewModel.body">

          <!-- Logo -->
          <custom-logo :view-model="presenter.viewModel.bodyLogo"/>

          <!-- Space -->
          <custom-space :view-model="presenter.viewModel.bodySectionSpace"/>

          <!-- Space -->
          <custom-space
              :ref="presenter.viewModel.bodyWia.ref"
              :view-model="presenter.viewModel.bodySectionSpace"
          />

          <!-- Who I am ? -->
          <custom-wia
              :view-model="presenter.viewModel.bodyWia"
              @coachSocialClick="controller.coachSocialTapped($event)"
          />

          <!-- Space -->
          <custom-space :view-model="presenter.viewModel.bodySectionSpace"/>

          <!-- Space -->
          <custom-space
              :ref="presenter.viewModel.bodyWaylf.ref"
              :view-model="presenter.viewModel.bodySectionSpace"
          />

          <!-- What are you looking for -->
          <custom-waylf :view-model="presenter.viewModel.bodyWaylf"/>

          <!-- Space -->
          <custom-space
              :ref="presenter.viewModel.bodyHiw.ref"
              :view-model="presenter.viewModel.bodySectionSpace"
          />

          <!-- How I Work -->
          <custom-hiw
              :view-model="presenter.viewModel.bodyHiw"
              @assetClick="controller.assetTapped($event)"
          />

          <!-- Space -->
          <custom-space
              :ref="presenter.viewModel.bodyPrices.ref"
              :view-model="presenter.viewModel.bodySectionSpace"
          />

          <!-- Space -->
          <custom-space :view-model="presenter.viewModel.bodySectionSpace"/>

          <!-- Prices -->
          <custom-prices :view-model="presenter.viewModel.bodyPrices"/>

          <!-- Space -->
          <custom-space :view-model="presenter.viewModel.bodySectionSpace"/>

          <!-- Catch phrase -->
          <custom-catch-phrase :view-model="presenter.viewModel.bodyCatchPhrase"/>

          <!-- Space -->
          <custom-space
              :ref="presenter.viewModel.bodyLocality.ref"
              :view-model="presenter.viewModel.bodySectionSpace"
          />

          <!-- Locality -->
          <custom-locality
              :view-model="presenter.viewModel.bodyLocality"
              @mapClick="controller.mapTapped($event)"
          />

          <!-- Space -->
          <custom-space
              :ref="presenter.viewModel.bodyContact.ref"
              :view-model="presenter.viewModel.bodySectionSpace"
          />

          <!-- Contact -->
          <custom-contact
              :view-model="presenter.viewModel.bodyContact"
              @contactLinkClick="controller.contactLinkTapped($event)"
          />

          <!-- Space -->
          <custom-space :view-model="presenter.viewModel.bodySectionSpace"/>

          <!-- Footer -->
          <custom-footer :view-model="presenter.viewModel.bodyFooter"/>
      </custom-body>
    </v-app>
</template>

<script>

// Components
import AppBarComponent from '@/app/common/component/app-bar/AppBarComponent'
import NavigationComponent from '@/app/common/component/navigation/NavigationComponent'
import BodyComponent from '@/app/common/component/body/BodyComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import LogoComponent from '@/app/scene/app/component/AppLogoComponent'
import WiaComponent from '@/app/scene/app/component/AppWiaComponent'
import WaylfComponent from '@/app/scene/app/component/AppWaylfComponent'
import HiwComponent from '@/app/scene/app/component/AppHiwComponent'
import PricesComponent from '@/app/scene/app/component/AppPricesComponent'
import CatchPhraseComponent from '@/app/scene/app/component/AppCatchPhraseComponent'
import LocalityComponent from '@/app/scene/app/component/AppLocalityComponent'
import ContactComponent from '@/app/scene/app/component/AppContactComponent'
import FooterComponent from '@/app/scene/app/component/AppFooterComponent'

import PresentationInjection from '@/presentation/scene/app/appPresentationInjection'

export default {
  name: 'AppVue',

  components: {
    customAppBar: AppBarComponent,
    customNavigation: NavigationComponent,
    customBody: BodyComponent,
    customSpace: SpaceComponent,
    customLogo: LogoComponent,
    customWia: WiaComponent,
    customWaylf: WaylfComponent,
    customHiw: HiwComponent,
    customPrices: PricesComponent,
    customCatchPhrase: CatchPhraseComponent,
    customLocality: LocalityComponent,
    customContact: ContactComponent,
    customFooter: FooterComponent
  },

  data: () => ({
    /** @type AppController */
    controller: null,
    /** @type AppPresenter */
    presenter: null
  }),

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$refs)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  mounted () {
    this.controller.vueMounted()
  },

  destroyed () {
    this.controller.vueDestroyed()

    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>

<style lang="sass" scoped>
@import 'app-style.scss'
</style>
