<template>
  <!-- Card -->
  <custom-card :view-model="viewModel">

    <!-- Avatar -->
    <custom-image :view-model="viewModel.avatar"/>

    <!-- Avatar space -->
    <custom-space :view-model="viewModel.avatarBottomSpace"/>

    <!-- Social buttons space -->
    <custom-row :view-model="viewModel.socialRow">
      <custom-col
          v-for="(item, i) in viewModel.socialIconButtons"
          :key="i"
          :view-model="viewModel.socialCol"
      >
        <div :class="viewModel.socialContentClasses">
          <custom-icon-button
              :view-model="item"
              @click="socialClicked(i)"
          />
        </div>
      </custom-col>
    </custom-row>
  </custom-card>
</template>

<script>
import AppWiaCoachCardViewModel from '@/presentation/scene/app/view-model/wia/appWiaCoachCardViewModel'
import CardComponent from '@/app/common/component/card/CardComponent'
import ImageComponent from '@/app/common/component/image/ImageComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import IconButtonComponent from '@/app/common/component/button/IconButtonComponent'
import { Events } from '@/presentation/main'

export default {
  name: 'AppWiaCardComponent',

  components: {
    customCard: CardComponent,
    customSpace: SpaceComponent,
    customImage: ImageComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customIconButton: IconButtonComponent
  },

  props: {
    viewModel: {
      type: AppWiaCoachCardViewModel,
      required: true
    }
  },

  methods: {
    socialClicked (index) {
      this.$emit(Events.coachSocialClicked, index)
    }
  }
}
</script>
