import { Events } from '@/presentation/main'

export default class AppController {
  // MARK: - Properties

  /** @type {AppInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {AppInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
    this._loadBus()
  }

  // MARK: - Life cycle vue

  vueMounted () {
    this._interactor.vueMounted()
  }

  vueDestroyed () {
  }

  // MARK: - Methods

  menuTapped () {
    this._interactor.menuTappedFromController()
  }

  /**
   * @param {string} anchor to scroll to correct div
   */
  navigationItemTapped (anchor) {
    this._interactor.navigationItemTappedFromController(anchor)
  }

  closeNavigationTapped () {
    this._interactor.closeNavigationTappedFromController()
  }

  /**
   * @param {int} index tapped
   */
  assetTapped (index) {
    this._interactor.assetTappedFromController(index)
  }

  /**
   * @param {int} index tapped
   */
  coachSocialTapped (index) {
    this._interactor.coachSocialTappedFromController(index)
  }

  /**
   * @param {int} index tapped
   */
  mapTapped (index) {
    this._interactor.mapTappedFromController(index)
  }

  /**
   * @param {int} index tapped
   */
  contactLinkTapped (index) {
    this._interactor.contactLinkTappedFromController(index)
  }

  // MARK: - Bus

  _loadBus () {
    const self = this

    // Scroll
    document.addEventListener(Events.scroll, () => {
      self._interactor.scrollFiredFromController()
    }, true)
  }
}
