import AppPricesOfferCardViewModel from '@/presentation/scene/app/view-model/prices/appPricesOfferCardViewModel'
import ImageViewModel from '@/presentation/view-model/image/imageViewModel'
import ChipViewModel from '@/presentation/view-model/chip/chipViewModel'

export default class AppPricesOffer {
  /** @return {AppPricesOfferCardViewModel} */
  static get card () {
    const image = new ImageViewModel()
    image.src = this._image

    const item = new AppPricesOfferCardViewModel()
    item.image = image
    item.titleKey = this._titleKey
    item.descriptionKey = this._descriptionKey
    item.pricePrefixValue = this._offerPrefixKey

    const items = []
    for (let i = 1; i <= this._numberOfOffers; i++) {
      const item = new ChipViewModel()
      item.value = i
      item.textKey = this._offerPrefixKey + i
      items.push(item)
    }
    item.offersItems = items

    return item
  }

  /** @return {string} */
  static get _image () {
    return 'img/prices/1.jpg'
  }

  /** @return {string} */
  static get _titleKey () {
    return 'prices_session_subtitle'
  }

  /** @return {string} */
  static get _descriptionKey () {
    return 'prices_session_description'
  }

  /** @return {int} */
  static get _numberOfOffers () {
    return 3
  }

  /** @return {string} */
  static get _offerPrefixKey () {
    return 'prices_session_'
  }
}
