<template>
  <!-- Content -->
  <custom-row :view-model="viewModel.contentRow">
    <custom-col :view-model="viewModel.contentCol">

      <!-- Title -->
      <custom-section-title :view-model="viewModel.title"/>

      <!-- Card -->
      <custom-row :view-model="viewModel.coachItemRow">
        <custom-col :view-model="viewModel.coachItemCol">
          <custom-card
              :view-model="viewModel.coachCard"
              @coachSocialClick="socialClicked($event)"
          />
        </custom-col>
      </custom-row>

      <!-- Card space -->
      <custom-space :view-model="viewModel.coachItemBottomSpace"/>

      <!-- Text -->
      <p v-html="viewModel.text"/>

    </custom-col>
  </custom-row>
</template>

<script>
import AppWiaViewModel from '@/presentation/scene/app/view-model/appWiaViewModel'
import SectionTitleComponent from '@/app/common/component/section-title/SectionTitleComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import CardComponent from '@/app/scene/app/component/wia/AppWiaCardComponent'
import { Events } from '@/presentation/main'

export default {
  name: 'AppWiaComponent',

  components: {
    customSectionTitle: SectionTitleComponent,
    customSpace: SpaceComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customCard: CardComponent
  },

  props: {
    viewModel: {
      type: AppWiaViewModel,
      required: true
    }
  },

  methods: {
    socialClicked (socialIndex) {
      this.$emit(Events.coachSocialClicked, socialIndex)
    }
  }
}
</script>
