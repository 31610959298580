<template>
  <custom-card :view-model="viewModel">
    <custom-image :view-model="viewModel.image"/>
  </custom-card>
</template>

<script>
import AppLocalityPictureCardViewModel from '@/presentation/scene/app/view-model/locality/appLocalityPictureCardViewModel'
import CardComponent from '@/app/common/component/card/CardComponent'
import ImageComponent from '@/app/common/component/image/ImageComponent'

export default {
  name: 'AppLocalityPictureCardComponent',

  components: {
    customCard: CardComponent,
    customImage: ImageComponent
  },

  props: {
    viewModel: {
      type: AppLocalityPictureCardViewModel,
      required: true
    }
  }
}
</script>
