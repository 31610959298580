import Vue from 'vue'
import Vuetify from 'vuetify'

// Custom Icon
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import IconLogo from '@/presentation/assets/icon/logo/LogoIcon'
import IconSocialInstagram from '@/presentation/assets/icon/social/InstagramIcon'
import IconSocialFacebook from '@/presentation/assets/icon/social/FacebookIcon'
import IconSocialFacebookPlain from '@/presentation/assets/icon/social/FacebookPlainIcon'
import IconWaylf1 from '@/presentation/assets/icon/waylf/Waylf1Icon'
import IconWaylf2 from '@/presentation/assets/icon/waylf/Waylf2Icon'
import IconWaylf3 from '@/presentation/assets/icon/waylf/Waylf3Icon'
import IconWaylf4 from '@/presentation/assets/icon/waylf/Waylf4Icon'
import IconWaylf5 from '@/presentation/assets/icon/waylf/Waylf5Icon'
import IconWaylf6 from '@/presentation/assets/icon/waylf/Waylf6Icon'

// Localization
import VueI18n from 'vue-i18n'
import EnglishLocalization from '@/presentation/assets/localization/en.json'

Vue.use(Vuetify)
Vue.use(VueI18n)

const messages = {
  en: {
    $vuetify: EnglishLocalization
  }
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en',
  messages,
  silentTranslationWarn: process.env.NODE_ENV === 'production'
})

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        // Colors
        black: '#F4F4F4',
        blue: '#3b5998',
        green: '#05a860',
        grey: '#424242',
        main: '#38FF5F',
        red: '#FF5A5A',
        secondary: '#1E1716',
        white: '#070707'
      },
      dark: {
        // Colors
        black: '#0A0A0A',
        blue: '#3b5998',
        green: '#05a860',
        grey: '#424242',
        main: '#38FF5F',
        red: '#FF5A5A',
        secondary: '#1E1615',
        white: '#F7F7F7'
      }
    }
  },
  icons: {
    iconfont: 'mdi',
    values: {
      iconLogo: {
        component: IconLogo
      },
      iconSocialInstagram: {
        component: IconSocialInstagram
      },
      iconSocialFacebook: {
        component: IconSocialFacebook
      },
      iconSocialFacebookPlain: {
        component: IconSocialFacebookPlain
      },
      iconWaylf1: {
        component: IconWaylf1
      },
      iconWaylf2: {
        component: IconWaylf2
      },
      iconWaylf3: {
        component: IconWaylf3
      },
      iconWaylf4: {
        component: IconWaylf4
      },
      iconWaylf5: {
        component: IconWaylf5
      },
      iconWaylf6: {
        component: IconWaylf6
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, ...params)
  }
})
