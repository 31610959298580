// MARK: - Constants

const WIA = 0
const WAYLF = 1
const HIW = 2
const PRICES = 3
const LOCALITY = 4
const CONTACT = 5

// MARK: - Enum

export default {
  WIA,
  WAYLF,
  HIW,
  PRICES,
  LOCALITY,
  CONTACT,

  /**
     * @return {[int]} return all enum values
     */
  all () {
    return [
      WIA,
      WAYLF,
      HIW,
      PRICES,
      LOCALITY,
      CONTACT
    ]
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  nameKey (value) {
    switch (value) {
      case WIA:
        return 'tab_wia'
      case WAYLF:
        return 'tab_waylf'
      case HIW:
        return 'tab_hiw'
      case PRICES:
        return 'tab_prices'
      case LOCALITY:
        return 'tab_locality'
      default:
        return 'tab_contact'
    }
  },

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  anchorKey (value) {
    switch (value) {
      case WIA:
        return 'wia'
      case WAYLF:
        return 'waylf'
      case HIW:
        return 'hiw'
      case PRICES:
        return 'prices'
      case LOCALITY:
        return 'locality'
      default:
        return 'contact'
    }
  },

  /**
     * @param {string} value corresponding to anchor key
     * @return {int}
     */
  value (value) {
    switch (value) {
      case this.anchorKey(WIA):
        return WIA
      case this.anchorKey(WAYLF):
        return WAYLF
      case this.anchorKey(HIW):
        return HIW
      case this.anchorKey(PRICES):
        return PRICES
      case this.anchorKey(LOCALITY):
        return LOCALITY
      default:
        return CONTACT
    }
  }
}
