import AppWiaCoachCardViewModel from '@/presentation/scene/app/view-model/wia/appWiaCoachCardViewModel'
import ImageViewModel from '@/presentation/view-model/image/imageViewModel'

// MARK: - Enum

export default class AppWiaCoach {
  /** @return {AppWiaCoachCardViewModel} */
  static get card () {
    const avatar = new ImageViewModel()
    avatar.src = this._avatar

    const item = new AppWiaCoachCardViewModel()
    item.avatar = avatar

    return item
  }

  /** @return {string} */
  static get _avatar () {
    return 'img/coach/1.jpg'
  }
}
