<template>
  <svg width="510px" height="510px" viewBox="0 0 510 510" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="icon/white" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="g14" transform="translate(255.000000, 254.833321) scale(1, -1) translate(-255.000000, -254.833321) translate(45.000000, 1.333321)" fill-rule="nonzero">
        <g id="g20" transform="translate(33.800932, 251.643460)" fill="#38FF5F">
          <path d="M257.333327,122.666664 L226.163994,149.854663 C234.977327,178.187996 229.694661,192.241329 229.694661,192.241329 C205.661328,217.838661 66.2919983,221.613328 41.9626656,192.241329 C40.2173323,183.646662 44.3079989,146.44533 49.7466654,132.95733 C98.9226642,160.975996 161.250663,164.406663 217.859995,132.003997 C245.115994,115.42133 251.023994,103.603997 241.303994,84.9253312 C231.582661,66.2453317 171.151996,0.482666655 132.654663,0.482666655 C94.157331,0.482666655 52.9039987,51.665332 49.7266654,55.0826653 L80.011998,83.2533313 C83.4586646,79.5466647 117.654664,44.1973322 132.87733,44.1973322 C148.098663,44.1973322 200.069328,89.4999978 195.622662,93.3119977 C159.661329,113.387997 116.839997,117.835997 71.2853316,93.8839977 C44.3306656,81.4986646 24.6893327,92.3639977 12.9079997,114.47333 C7.66799981,124.306664 -10.2439997,192.638662 8.28933313,216.325328 C26.5079993,240.394661 63.6306651,253.273327 118.634664,254.603994 C156.446663,255.53066 193.429328,250.563994 202.465328,248.34266 C202.465328,248.34266 236.665327,241.727994 259.351994,216.325328 C279.869326,189.121329 257.333327,122.666664 257.333327,122.666664" id="path22"></path>
        </g>
        <g id="g24" transform="translate(0.308800, 69.291865)" fill="#FFFFFF">
          <path d="M38.6666657,274.66666 C14.7119996,245.718661 0.317333325,208.571995 0.317333325,168.062662 C0.317333325,75.6519981 75.2293315,0.739999982 167.639996,0.739999982 C260.049327,0.739999982 334.962658,75.6519981 334.962658,168.062662 C334.962658,206.343995 321.507992,241.173327 300.477326,269.817327 C292.521326,254.061327 272.919993,230.813328 272.919993,230.813328 C283.883993,212.458661 290.183993,190.995995 290.183993,168.062662 C290.183993,100.382664 235.318661,45.5186655 167.639996,45.5186655 C99.9599975,45.5186655 45.0959989,100.382664 45.0959989,168.062662 C45.0959989,191.422662 54.3946653,225.343994 72.4813315,245.693327 L38.6666657,274.66666 Z" id="path26"></path>
        </g>
        <g id="g28" transform="translate(193.200529, 0.133067)" fill="#38FF5F">
          <path d="M14.6666663,17.3333329 L15.3226663,13.6959997 C15.9479996,10.2426664 17.0946662,8.79466645 19.3986662,9.2093331 C21.7253328,9.63066643 21.8426661,12.1079997 21.3946661,14.585333 C20.4986662,19.5413328 19.3066662,20.8079995 13.1359997,24.3253327 C6.31466651,28.266666 2.73733326,30.6853326 1.33999997,38.4186657 C-0.0159999996,45.9199989 1.21866664,53.2826653 11.1666664,55.081332 C21.7279995,56.9879986 24.5879994,50.0666654 25.674666,44.0559989 L26.2213327,41.0333323 L16.6853329,39.3106657 L16.1159996,42.4559989 C15.5399996,45.6413322 14.7319996,47.1239988 12.7346663,46.7626655 C10.8706664,46.4253322 10.5599997,44.7133322 11.0959997,41.7413323 C11.6679997,38.5786657 12.525333,37.1199991 17.4799996,34.5173325 C26.2999993,29.8453326 29.6866659,26.9373327 31.2493326,18.2906662 C32.7359992,10.0679997 31.1493326,2.7866666 20.7173328,0.901333311 C10.6999997,-0.910666644 6.7506665,4.61599988 5.34399987,12.3986664 L4.77466655,15.5453329 L14.6666663,17.3333329 Z" id="path30"></path>
        </g>
        <g id="g32" transform="translate(235.777327, 14.600533)" fill="#38FF5F">
          <path d="M11.9999997,38.6666657 L22.6586661,13.5599997 C23.9466661,10.5266664 25.658666,9.38666643 27.7693326,10.2826664 C29.9719993,11.2173331 30.3613326,13.0759997 28.9893326,16.3053329 L18.3199995,41.435999 C17.0173329,44.5039989 15.5013329,45.7133322 13.1266663,44.7039989 C11.1146664,43.8506656 10.6173331,41.9253323 11.9999997,38.6666657 M28.4093326,44.3759989 L38.0826657,21.5893328 C42.2213323,11.8439997 39.407999,5.71599986 30.8213326,2.07199995 C22.6733328,-1.38799997 16.6653329,1.11599997 12.6119997,10.6639997 L2.78933326,33.8013325 C-1.2373333,43.2839989 2.21733328,49.6373321 9.78133309,52.8479987 C17.3933329,56.0799986 24.2453327,54.185332 28.4093326,44.3759989" id="path34"></path>
        </g>
        <g id="g36" transform="translate(303.808526, 57.388265)" fill="#FFFFFF">
          <path d="M30.6666659,19.9999995 L31.0399992,19.5839995 C35.6586658,14.4199996 38.2146657,8.91599978 32.2266659,3.55999991 C26.5839993,-1.48666663 21.5719995,1.1333333 16.1226663,7.22399982 L5.33199987,19.2866662 C0.714666649,24.4479994 -0.873333311,30.0826659 4.59199989,34.9719991 C10.4799997,40.239999 15.5159996,36.9373324 19.1853329,32.8359992 L20.3026662,31.5866659 L16.5173329,28.2013326 L15.6346663,29.1879993 C13.0906663,32.0293325 10.3239997,34.1719991 7.45866648,31.6079992 C4.97999988,29.3919993 5.63733319,26.646666 9.05999977,22.8213328 L20.6293328,9.88799975 C23.2159994,6.99733316 26.5319993,4.39599989 29.3813326,6.94399983 C31.8839992,9.18266644 31.3439992,11.6279997 27.6333326,15.7746663 L26.8813327,16.6146663 L30.6666659,19.9999995 Z" id="path38"></path>
        </g>
        <g id="g40" transform="translate(332.114525, 91.530264)" fill="#FFFFFF">
          <path d="M9.3333331,18.6666662 L23.5293327,7.90133314 C27.2679993,5.06666654 30.5786659,4.56799989 32.7066658,7.37333315 C34.6759991,9.97199975 34.0426658,12.7866663 29.6746659,16.0986663 L15.5013329,26.846666 C12.1546664,29.3826659 8.86266645,30.0693326 6.65599983,27.1573327 C4.47199989,24.2773327 5.64666653,21.4626661 9.3333331,18.6666662 M18.7013329,30.8626659 L32.4853325,20.4119995 C39.5173323,15.0799996 40.047999,9.5093331 36.2746658,4.53199989 C32.4946659,-0.451999989 27.1999993,-1.3253333 20.2973328,3.91066657 L6.23599984,14.5719996 C0.201333328,19.1466662 -0.826666646,24.8359994 3.08799992,29.9986659 C7.13466649,35.3346658 12.7359997,35.3866658 18.7013329,30.8626659" id="path42"></path>
        </g>
        <g id="g44" transform="translate(354.753858, 130.133063)" fill="#FFFFFF">
          <path d="M22.6666661,19.9999995 C22.4359994,19.5266662 21.9853328,18.5999995 21.7346661,18.0853329 C19.6293328,13.7599997 20.2439995,10.3333331 25.6773327,7.68799981 L26.298666,7.38399982 C29.7186659,5.71866652 33.0333325,5.37599987 34.4906658,8.36799979 C36.2319991,11.9439997 33.8786658,14.5399996 28.2933326,17.2599996 L22.6666661,19.9999995 Z M36.5279991,18.8093329 C39.059999,17.5759996 41.5813323,16.4053329 42.6279989,16.0173329 L40.547999,11.7479997 C39.5293323,12.0079997 37.9946657,12.573333 37.3399991,12.8599997 C39.583999,10.3386664 39.2573324,7.63599981 38.047999,5.15199987 C35.5079991,-0.0639999984 29.9226659,0.0973333309 24.2679994,2.85199993 L23.6213327,3.16666659 C15.5493329,7.09733316 14.8879996,13.4719997 18.0439995,19.9519995 C18.3173329,20.5133328 18.5479995,20.9866661 18.9013329,21.7133328 L12.8639997,24.6519994 C9.4573331,26.3119993 6.95199983,26.7733327 5.48399986,23.7599994 C4.02933323,20.7719995 5.89466652,18.8119995 8.88666644,17.3559996 L9.94799975,16.8386662 L7.74799981,12.3199997 L6.73733316,12.8106663 C1.69199996,15.269333 -1.57866663,19.3253329 1.59333329,25.838666 C4.61199988,32.0359992 9.52399976,31.9599992 14.7786663,29.3999993 L36.5279991,18.8093329 Z" id="path46"></path>
        </g>
        <g id="g48" transform="translate(369.666657, 175.368796)" fill="#FFFFFF">
          <path d="M30.6666659,19.9999995 L31.2066659,19.8559995 C37.8986657,18.0693329 43.0533323,14.8666663 40.9813323,7.10399982 C39.027999,-0.207999995 33.3999992,-0.765333314 25.5039994,1.34399997 L9.86799975,5.51866653 C3.17733325,7.30399982 -1.23999997,11.1453331 0.651999984,18.2319995 C2.6906666,25.8639994 8.71199978,25.8613327 14.0279996,24.4426661 L15.6466663,24.0106661 L14.337333,19.1039995 L13.0586663,19.4453328 C9.3733331,20.4279995 5.88533319,20.7026661 4.89333321,16.9879996 C4.0359999,13.7759997 6.08933318,11.841333 11.0493331,10.5173331 L27.8133326,6.04133318 C31.5613325,5.03999987 35.7613324,4.68399988 36.7479991,8.37599979 C37.6133324,11.6199997 35.8213324,13.3679997 30.4439992,14.8039996 L29.3573326,15.093333 L30.6666659,19.9999995 Z" id="path50"></path>
        </g>
        <g id="g52" transform="translate(360.480524, 219.363594)" fill="#FFFFFF">
          <path d="M1.3333333,9.3333331 L20.2506662,8.0839998 C17.9746662,9.23466644 16.1226663,11.7319997 16.3879996,15.7493329 C16.7853329,21.7719995 21.4333328,23.2613328 27.286666,22.8759994 L58.0039985,20.8479995 L57.6693319,15.7759996 L28.2119993,17.7213329 C24.7453327,17.9493329 21.2213328,17.7493329 20.9559995,13.7186663 C20.6493328,9.09333311 24.1933327,7.82266647 30.3853326,7.41466648 L57.0013319,5.65599986 L56.6653319,0.583999985 L0.998666642,4.26133323 L1.3333333,9.3333331 Z" id="path54"></path>
        </g>
        <g id="g56" transform="translate(360.940124, 262.880527)" fill="#FFFFFF">
          <path d="M15.9999996,2.6666666 L15.4613329,7.72133314 L55.7879986,12.0239997 L56.3266653,6.96933316 L15.9999996,2.6666666 Z M0.841333312,1.07866664 L0.302666659,6.13333318 L8.38799979,6.99599983 L8.92666644,1.94133328 L0.841333312,1.07866664 Z" id="path58"></path>
        </g>
        <g id="g60" transform="translate(367.165324, 292.433859)" fill="#FFFFFF">
          <path d="M17.3333329,3.9999999 C9.53199976,1.75733329 7.47066648,1.1653333 5.52666653,0.522666654 L4.18933323,5.17199987 C5.03199987,5.52799986 6.31866651,6.00933318 8.1119998,6.52533317 C4.78399988,6.81733316 2.50133327,8.73066645 1.4653333,12.3346664 C0.27066666,16.4866663 1.60799996,20.6106662 8.72133312,22.6559994 L38.8693324,31.3213326 L40.2733323,26.4359993 L11.0933331,18.0479995 C7.25999982,16.9466662 5.55999986,15.0719996 6.41866651,12.081333 C7.39199982,8.69599978 9.65199976,7.08133316 17.5239996,9.34399977 L43.0759989,16.6893329 L44.4799989,11.8039997 L17.3333329,3.9999999 Z" id="path62"></path>
        </g>
        <g id="g64" transform="translate(348.483058, 334.303725)" fill="#FFFFFF">
          <path d="M18.6666662,7.9999998 L32.7506658,15.2399996 C37.6146657,17.7399996 39.327999,20.2493328 37.8746657,23.0759994 C35.6333324,27.4373326 30.7639992,25.7959994 25.8039994,23.2466661 L15.1306663,17.7599996 C10.7226664,15.4946663 7.48399981,13.237333 9.58266643,9.1573331 C11.2693331,5.87466652 14.425333,5.81999985 18.6666662,7.9999998 Z M36.9639991,34.6399991 C44.1159989,38.315999 50.5933321,40.5986657 55.0786653,31.8759992 C58.8119985,24.6093327 54.557332,20.6493328 50.6613321,18.6466662 L50.4733321,18.5506662 L48.1266655,23.1173328 L48.3613321,23.2373328 C50.3373321,24.2533327 52.9239987,26.5239993 51.0733321,30.1239992 C49.0679988,34.0266658 45.7186655,33.4239992 40.363999,30.6719992 L35.5586658,28.202666 C38.6213324,28.5493326 40.915999,27.2959993 42.5826656,24.0546661 C45.2813322,18.8039995 42.7026656,14.5826663 35.4826658,10.8706664 L20.7919995,3.31866658 C14.101333,-0.119999997 8.59999978,0.805333313 5.87066652,6.11333318 C3.97733323,9.79599976 5.01999987,12.4839997 6.54799984,13.905333 C5.54799986,13.3906663 4.12666656,12.8719997 3.27866658,12.557333 L1.26666663,16.4706663 C3.23733325,17.3933329 6.5826665,19.0239995 10.9493331,21.2679995 L36.9639991,34.6399991 Z" id="path66"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'LogoIcon'
})
</script>
