import String from '@/presentation/utils/string'

export default class IconViewModel {
  // MARK: - Properties

    /** @type {boolean} */
    _isVisible
    /** @type {string} */
    _name
    /** @type {string|null} */
    _classes
    /** @type {number|null} */
    _size

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._isVisible = true
      this._name = String.empty
      this._classes = null
      this._size = null
    }

    // MARK: - Getter

    /** @return {boolean} */
    get isVisible () {
      return this._isVisible
    }

    /** @return {string} */
    get name () {
      return this._name
    }

    /** @return {string|null} */
    get classes () {
      return this._classes
    }

    /** @return {number|null} */
    get size () {
      return this._size
    }

    // MARK: - Setter

    /** @param {boolean} value  */
    set isVisible (value) {
      this._isVisible = value
    }

    /** @param {string} value (link) of the icon classes */
    set name (value) {
      this._name = value
    }

    /** @param {string} value */
    set classes (value) {
      this._classes = value
    }

    /** @param {number} value size of the icon */
    set size (value) {
      this._size = value
    }
}
