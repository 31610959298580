<template>
  <svg width="43px" height="43px" viewBox="0 0 43 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M28.0228169,39.4 L28.0228169,24.6857143 L32.1370423,24.6857143 L32.5439437,19.5904762 L28.0228169,19.5904762 L28.0228169,17.4683095 C28.0228169,16.2505476 28.239831,15.7690476 29.2842113,15.7690476 L32.5439437,15.7690476 L32.5439437,9.4 L27.1095493,9.4 C23.0450563,9.4 21.2411268,11.4177143 21.2411268,15.2799048 L21.2411268,19.5904762 L16.72,19.5904762 L16.72,24.6857143 L21.2411268,24.6857143 L21.2411268,39.4 L11.72,39.4 C7.301722,39.4 3.72,35.818278 3.72,31.4 L3.72,11.4 C3.72,6.981722 7.301722,3.4 11.72,3.4 L31.72,3.4 C36.138278,3.4 39.72,6.981722 39.72,11.4 L39.72,31.4 C39.72,35.818278 36.138278,39.4 31.72,39.4 L28.0228169,39.4 Z M31.72,0.4 L11.72,0.4 C5.64486775,0.4 0.72,5.32486775 0.72,11.4 L0.72,31.4 C0.72,37.4751322 5.64486775,42.4 11.72,42.4 L31.72,42.4 C37.7951322,42.4 42.72,37.4751322 42.72,31.4 L42.72,11.4 C42.72,5.32486775 37.7951322,0.4 31.72,0.4 Z" id="path-facebook-1"></path>
    </defs>
    <g id="web/pictos/facebook" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-facebook-2" fill="white">
        <use xlink:href="#path-facebook-1"></use>
      </mask>
      <use id="Mask" fill="#000000" xlink:href="#path-facebook-1"></use>
      <g id="color/-white" mask="url(#mask-facebook-2)" fill="#FFFFFF">
        <rect id="Rectangle" x="0" y="0" width="43" height="43"></rect>
      </g>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'FacebookIcon'
})
</script>
