import AppLocalityPictureCardViewModel from '@/presentation/scene/app/view-model/locality/appLocalityPictureCardViewModel'
import ImageViewModel from '@/presentation/view-model/image/imageViewModel'

export default class AppLocalityPictures {
  /**
     * @return {[int]} return all enum values
     */
  static get all () {
    return [
      1,
      2,
      3,
      4
    ]
  }

  /**
     * @param {int} value corresponding to this enum
     * @return {AppLocalityPictureCardViewModel}
     */
  static card (value) {
    const image = new ImageViewModel()
    image.src = this._image(value)

    const item = new AppLocalityPictureCardViewModel()
    item.key = value
    item.image = image

    return item
  }

  /**
     * @param {int} value corresponding to this enum
     * @return {string}
     */
  static _image (value) {
    return 'img/hall/' + value + '.jpg'
  }
}
