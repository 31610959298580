<template>
  <svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g id="footer-facebook" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M39.7460938,0 L10.2539062,0 C4.59990234,0 0,4.59990234 0,10.2539062 L0,39.7460938 C0,45.4000977 4.59990234,50 10.2539062,50 L39.7460938,50 C45.4000977,50 50,45.4000977 50,39.7460938 L50,10.2539062 C50,4.59990234 45.4000977,0 39.7460938,0 Z" id="Shape" fill="#4267B2" fill-rule="nonzero"></path>
      <path d="M22,22.952381 L16,22.952381 L16,29.7142857 L22,29.7142857 L22,50 L31,50 L31,29.7142857 L36.46,29.7142857 L37,22.952381 L31,22.952381 L31,20.1360476 C31,18.5199524 31.288,17.8809524 32.674,17.8809524 L37,17.8809524 L37,9.42857143 L29.788,9.42857143 C24.394,9.42857143 22,12.1062857 22,17.2318095 L22,22.952381 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'PlayStoreIcon'
})
</script>
