<template>
  <svg width="43px" height="43px" viewBox="0 0 43 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M31.72,3.4 C36.138278,3.4 39.72,6.981722 39.72,11.4 L39.72,31.4 C39.72,35.818278 36.138278,39.4 31.72,39.4 L11.72,39.4 C7.301722,39.4 3.72,35.818278 3.72,31.4 L3.72,11.4 C3.72,6.981722 7.301722,3.4 11.72,3.4 L31.72,3.4 L31.72,3.4 Z M31.72,0.4 C37.7951322,0.4 42.72,5.32486775 42.72,11.4 L42.72,31.4 C42.72,37.4751322 37.7951322,42.4 31.72,42.4 L11.72,42.4 C5.64486775,42.4 0.72,37.4751322 0.72,31.4 L0.72,11.4 C0.72,5.32486775 5.64486775,0.4 11.72,0.4 L31.72,0.4 Z M21.72,12.4 C26.6905627,12.4 30.72,16.4294373 30.72,21.4 C30.72,26.3705627 26.6905627,30.4 21.72,30.4 C16.7494373,30.4 12.72,26.3705627 12.72,21.4 C12.72,16.4294373 16.7494373,12.4 21.72,12.4 Z M21.72,9.4 C16.8664531,9.4 12.4908176,12.3237061 10.6334456,16.8077988 C8.77607361,21.2918915 9.80274267,26.4533054 13.2347186,29.8852814 C16.6666946,33.3172573 21.8281085,34.3439264 26.3122012,32.4865544 C30.7962939,30.6291824 33.72,26.2535469 33.72,21.4 C33.72,14.772583 28.347417,9.4 21.72,9.4 Z M33.22,10.9 C34.3245695,10.9 35.22,10.0045695 35.22,8.9 C35.22,7.7954305 34.3245695,6.9 33.22,6.9 C32.1154305,6.9 31.22,7.7954305 31.22,8.9 C31.22,10.0045695 32.1154305,10.9 33.22,10.9 Z" id="path-instagram-1"></path>
    </defs>
    <g id="web/pictos/instagram" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask id="mask-instagram-2" fill="white">
        <use xlink:href="#path-instagram-1"></use>
      </mask>
      <use id="Mask" fill="#000000" xlink:href="#path-instagram-1"></use>
      <g id="color/-white" mask="url(#mask-instagram-2)" fill="#FFFFFF">
        <rect id="Rectangle" x="0" y="0" width="43" height="43"></rect>
      </g>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'InstagramIcon'
})
</script>
