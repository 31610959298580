import PresentationKey from '@/presentation/enum/presentationKey'
import Color from '@/presentation/utils/color'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import Icon from '@/presentation/utils/icon'
import IconViewModel from '@/presentation/view-model/icon/iconViewModel'
import TabViewModel from '@/presentation/view-model/tabs/tabViewModel'
import TabsViewModel from '@/presentation/view-model/tabs/tabsViewModel'
import AppManager from '@/common/manager/appManager'

// MARK: - Constants

class Constants {
  static absolute = true
  static style = 'z-index: 3'
  static dark = true
  static color = Color.black
  static shrinkOnScroll = false
  static prominent = false
  static dense = false
  static elevateOnScroll = false
  static scrollTarget = PresentationKey.SCROLLING

  static BurgerIcon = {
    color: Color.main
  }

  static space = PresentationSpace.CLASSIC

  static Icon = {
    classes: 'my-1',
    name: Icon.LOGO,
    size: '45'
  }

  static Tabs = {
    classes: 'hidden-sm-and-down'
  }
}

// MARK: - Class

export default class AppBarViewModel {
  // MARK: - Properties

  /** @type {boolean} */
  _absolute
  /** @type {string} */
  _style
  /** @type {boolean} */
  _dark
  /** @type {string} */
  _color
  /** @type {boolean} */
  _shrinkOnScroll
  /** @type {boolean} */
  _prominent
  /** @type {boolean} */
  _dense
  /** @type {boolean} */
  _elevateOnScroll
  /** @type {string} */
  _scrollTarget

  /** @type {string} */
  _burgerIconColor

  /** @type {SpaceViewModel} */
  _leftSpace
  /** @type {IconViewModel} */
  _logoIcon
  /** @type {SpaceViewModel} */
  _rightSpace

  /** @type {TabsViewModel} */
  _tabs

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    this._absolute = Constants.absolute
    this._style = Constants.style
    this._dark = Constants.dark
    this._color = Constants.color
    this._shrinkOnScroll = Constants.shrinkOnScroll
    this._prominent = Constants.prominent
    this._dense = Constants.dense
    this._elevateOnScroll = Constants.elevateOnScroll
    this._scrollTarget = PresentationKey.ref(Constants.scrollTarget)

    this._burgerIconColor = Constants.BurgerIcon.color

    this._leftSpace = new SpaceViewModel()
    this._leftSpace.space = Constants.space

    this._logoIcon = new IconViewModel()
    this._logoIcon.name = Constants.Icon.name
    this._logoIcon.classes = Constants.Icon.classes
    this._logoIcon.size = Constants.Icon.size

    this._rightSpace = new SpaceViewModel()
    this._rightSpace.space = Constants.space

    this._tabs = null
  }

  // MARK: - Getter

  /** @return {boolean} */
  get absolute () {
    return this._absolute
  }

  /** @return {string} */
  get style () {
    return this._style
  }

  /** @return {boolean} */
  get dark () {
    return this._dark
  }

  /** @return {string} */
  get color () {
    return this._color
  }

  /** @return {boolean} */
  get shrinkOnScroll () {
    return this._shrinkOnScroll
  }

  /** @return {boolean} */
  get prominent () {
    return this._prominent
  }

  /** @return {boolean} */
  get dense () {
    return this._dense
  }

  /** @return {boolean} */
  get elevateOnScroll () {
    return this._elevateOnScroll
  }

  /** @return {string} */
  get scrollTarget () {
    return this._scrollTarget
  }

  /** @return {boolean} */
  get showBurgerIconColor () {
    return !this.showTabs
  }

  /** @return {string} */
  get burgerIconColor () {
    return this._burgerIconColor
  }

  /** @return {boolean} */
  get showLeftSpace () {
    return this.showTabs
  }

  /** @return {SpaceViewModel} */
  get leftSpace () {
    return this._leftSpace
  }

  /** @return {IconViewModel} */
  get logoIcon () {
    return this._logoIcon
  }

  /** @return {boolean} */
  get showRightSpace () {
    return !this.showTabs
  }

  /** @return {SpaceViewModel} */
  get rightSpace () {
    return this._rightSpace
  }

  /** @return {boolean} */
  get showTabs () {
    return !AppManager.shared.vuetify.breakpoint.smAndDown
  }

  /** @return {TabsViewModel} */
  get tabs () {
    return this._tabs
  }

  // MARK: - Setter

  /** @param {[int]} value corresponding to [PresentationNavigation] */
  set items (value) {
    const tabItems = value.map(e => {
      const item = new TabViewModel()
      item.nameKey = PresentationNavigation.nameKey(e)
      item.anchor = PresentationNavigation.anchorKey(e)
      return item
    })
    const tabs = new TabsViewModel()
    tabs.classes = Constants.Tabs.classes
    tabs.items = tabItems
    this._tabs = tabs
  }
}
