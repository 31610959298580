import AppInteractor from '@/domain/scene/app/appInteractor'

export default class AppDomainInjection {
  // MARK: - Properties

  /** @type {AppInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {AppPresenter} presenter
   */
  constructor (presenter) {
    this._interactor = new AppInteractor(presenter)
  }

  // MARK: - Getter

  /**
   * Get Interactor
   * @return {AppInteractor}
   */
  get interactor () {
    return this._interactor
  }
}
