import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import PresentationJustify from '@/presentation/enum/presentationJustify'
import Color from '@/presentation/utils/color'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import SectionTitleViewModel from '@/presentation/view-model/section-title/sectionTitleViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import Localization from '@/presentation/utils/localization'
import TextButtonViewModel from '@/presentation/view-model/button/textButtonViewModel'
import PresentationSpace from '@/presentation/enum/presentationSpace'

// MARK: - Constants

class Constants {
    static ref = PresentationNavigation.CONTACT

    static ContentRow = {
      justify: PresentationJustify.CENTER
    }

    static ContentCol = {
      cols: 11,
      xl: 7
    }

    static Title = {
      isCentered: false,
      key: 'contact_title',
      boldKey: 'contact_title_bold'
    }

    static Space = {
      content: PresentationSpace.LITTLE,
      section: PresentationSpace.MEDIUM
    }

    static Text = {
      key: 'contact_description',
      formKey: 'contact_description_2'
    }

    static LinkButton = {
      messengerColor: Color.blue,

      classes: 'mr-2 mb-2 ',
      otherClasses: Color.textColor(Color.black),
      messengerClasses: Color.textColor(Color.white),

      phone: 'contact_call_phone',
      messenger: 'contact_messenger'
    }
}

// MARK: - Class

export default class AppContactViewModel {
  // MARK: - Properties

    /** @type {string} */
    _ref
    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {SectionTitleViewModel} */
    _title
    /** @type {string} */
    _text
    /** @type {SpaceViewModel} */
    _textBottomSpace
    /** @type {RowViewModel} */
    _linkItemRow
    /** @type {[TextButtonViewModel]} */
    _linkButtons

    // MARK: - Constructor

    /**
     * @constructor
     * @param {any} validation
     */
    constructor (validation) {
      this._ref = PresentationNavigation.anchorKey(Constants.ref)

      this._contentRow = new RowViewModel()
      this._contentRow.justify = Constants.ContentRow.justify

      this._contentCol = new ColViewModel()
      this._contentCol.cols = Constants.ContentCol.cols
      this._contentCol.xl = Constants.ContentCol.xl

      this._title = new SectionTitleViewModel()
      this._title.setText(Constants.Title.key, Constants.Title.boldKey)
      this._title.isCentered = Constants.Title.isCentered

      this._text = Localization.getText(Constants.Text.key)

      this._textBottomSpace = new SpaceViewModel()
      this._textBottomSpace.space = Constants.Space.content

      this._linkItemRow = new RowViewModel()

      const linksButtonTitles = [Constants.LinkButton.phone, Constants.LinkButton.messenger]
      this._linkButtons = linksButtonTitles.map(e => {
        const linkButton = new TextButtonViewModel()
        linkButton.title = Localization.getText(e)

        // Change color for 'messenger' button
        let linkButtonClasses = Constants.LinkButton.classes
        if (e === Constants.LinkButton.messenger) {
          linkButtonClasses += Constants.LinkButton.messengerClasses
          linkButton.color = Constants.LinkButton.messengerColor
        } else {
          linkButtonClasses += Constants.LinkButton.otherClasses
        }
        linkButton.classes = linkButtonClasses

        return linkButton
      })
    }

    // MARK: - Getter

    /** @return {string} */
    get ref () {
      return this._ref
    }

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {SectionTitleViewModel} */
    get title () {
      return this._title
    }

    /** @return {string} */
    get text () {
      return this._text
    }

    /** @return {SpaceViewModel} */
    get textBottomSpace () {
      return this._textBottomSpace
    }

    /** @return {RowViewModel} */
    get linkItemRow () {
      return this._linkItemRow
    }

    /** @return {[TextButtonViewModel]} */
    get linkButtons () {
      return this._linkButtons
    }
}
