import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import PresentationJustify from '@/presentation/enum/presentationJustify'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import SectionTitleViewModel from '@/presentation/view-model/section-title/sectionTitleViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import Localization from '@/presentation/utils/localization'
import Style from '@/presentation/utils/style'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import String from '@/presentation/utils/string'
import WiaCoach from '@/presentation/scene/app/enum/appWiaCoach'

// MARK: - Constants

class Constants {
    static ref = PresentationNavigation.WIA

    static ContentRow = {
      justify: PresentationJustify.CENTER
    }

    static ContentCol = {
      cols: 11,
      xl: 7
    }

    static CoachItemRow = {
      justify: PresentationJustify.CENTER
    }

    static CoachItemCol = {
      cols: 12,
      sm: 5,
      md: 4
    }

    static Title = {
      isCentered: false,
      key: 'wia_title',
      boldKey: 'wia_title_bold'
    }

    static Space = {
      content: PresentationSpace.MEDIUM
    }

    static Text = {
      key: 'wia_description',
      bold1Key: 'wia_description_bold_1',
      bold2Key: 'wia_description_bold_1',
      bold3Key: 'wia_description_bold_2',
      bold4Key: 'wia_description_bold_3',
      bold5Key: 'wia_description_bold_4',
      bold6Key: 'wia_description_bold_5',
      bold7Key: 'wia_description_bold_6',
      bold8Key: 'wia_description_bold_7',
      styleColor: null
    }
}

// MARK: - Class

export default class AppWiaViewModel {
  // MARK: - Properties

    /** @type {string} */
    _ref
    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {SectionTitleViewModel} */
    _title
    /** @type {RowViewModel} */
    _coachItemRow
    /** @type {ColViewModel} */
    _coachItemCol
    /** @type {AppWiaCoachCardViewModel} */
    _coachCard
    /** @type {SpaceViewModel} */
    _coachItemBottomSpace
    /** @type {string} */
    _text

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._ref = PresentationNavigation.anchorKey(Constants.ref)

      this._contentRow = new RowViewModel()
      this._contentRow.justify = Constants.ContentRow.justify

      this._contentCol = new ColViewModel()
      this._contentCol.cols = Constants.ContentCol.cols
      this._contentCol.lx = Constants.ContentCol.xl

      this._title = new SectionTitleViewModel()
      this._title.setText(Constants.Title.key, Constants.Title.boldKey)

      this._coachItemRow = new RowViewModel()
      this._coachItemRow.justify = Constants.CoachItemRow.justify

      this._coachItemCol = new ColViewModel()
      this._coachItemCol.cols = Constants.CoachItemCol.cols
      this._coachItemCol.smAndGreater = Constants.CoachItemCol.sm

      this._coachCard = WiaCoach.card

      this._coachItemBottomSpace = new SpaceViewModel()
      this._coachItemBottomSpace.space = Constants.Space.content

      let text = Localization.getText(Constants.Text.key)
      text = String.addHtmllineBreak(text)
      const textStyles = [
        new Style(Constants.Text.bold1Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold2Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold3Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold4Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold5Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold6Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold7Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold8Key, Constants.Text.styleColor)
      ]
      this._text = Style.addStylesOnText(text, textStyles)
    }

    // MARK: - Getter

    /** @return {string} */
    get ref () {
      return this._ref
    }

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {SectionTitleViewModel} */
    get title () {
      return this._title
    }

    /** @return {RowViewModel} */
    get coachItemRow () {
      return this._coachItemRow
    }

    /** @return {ColViewModel} */
    get coachItemCol () {
      return this._coachItemCol
    }

    /** @return {AppWiaCoachCardViewModel} */
    get coachCard () {
      return this._coachCard
    }

    /** @return {SpaceViewModel} */
    get coachItemBottomSpace () {
      return this._coachItemBottomSpace
    }

    /** @return {string} */
    get text () {
      return this._text
    }
}
