<template>
  <div>
    <!-- Content -->
    <custom-row :view-model="viewModel.contentRow">
      <custom-col :view-model="viewModel.contentCol">

        <!-- Card -->
        <custom-card :view-model="viewModel.contentCard">

          <!-- Title -->
          <custom-section-title :view-model="viewModel.title"/>

          <!-- Text -->
          <p v-html="viewModel.text1"/>

          <!-- Map space -->
          <custom-space :view-model="viewModel.mapSpace"/>

          <!-- Map Buttons -->
          <custom-row :view-model="viewModel.mapItemRow">
            <div
                v-for="(item, i) in viewModel.mapButtons"
                :key="i"
            >
              <custom-button
                  :view-model="item"
                  @click="mapClicked(i)"
              />
            </div>
          </custom-row>

          <!-- Map space -->
          <custom-space :view-model="viewModel.mapSpace"/>

          <!-- Text -->
          <p v-html="viewModel.text2"/>

        </custom-card>
      </custom-col>
    </custom-row>

    <!-- Pictures -->
    <custom-row :view-model="viewModel.picturesContentRow">
      <custom-col :view-model="viewModel.picturesContentCol">
        <custom-row :view-model="viewModel.picturesRow">
          <custom-col
            v-for="item in viewModel.picturesCard"
            :key="item.key"
            :view-model="viewModel.picturesCol">
            <custom-picture-card :view-model="item"/>
          </custom-col>
        </custom-row>
      </custom-col>
    </custom-row>
  </div>
</template>

<script>
import AppLocalityViewModel from '@/presentation/scene/app/view-model/appLocalityViewModel'
import SectionTitleComponent from '@/app/common/component/section-title/SectionTitleComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import CardComponent from '@/app/common/component/card/CardComponent'
import TextButtonComponent from '@/app/common/component/button/TextButtonComponent'
import PictureCardComponent from '@/app/scene/app/component/locality/AppLocalityPictureCardComponent'
import { Events } from '@/presentation/main'

export default {
  name: 'AppLocalityComponent',

  components: {
    customSectionTitle: SectionTitleComponent,
    customSpace: SpaceComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customCard: CardComponent,
    customButton: TextButtonComponent,
    customPictureCard: PictureCardComponent
  },

  props: {
    viewModel: {
      type: AppLocalityViewModel,
      required: true
    }
  },

  methods: {
    mapClicked (index) {
      this.$emit(Events.mapClicked, index)
    }
  }
}
</script>
