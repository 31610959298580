import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import PresentationJustify from '@/presentation/enum/presentationJustify'
import Color from '@/presentation/utils/color'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import SectionTitleViewModel from '@/presentation/view-model/section-title/sectionTitleViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import Localization from '@/presentation/utils/localization'
import Style from '@/presentation/utils/style'
import TextButtonViewModel from '@/presentation/view-model/button/textButtonViewModel'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
    static ref = PresentationNavigation.HIW

    static ContentRow = {
      justify: PresentationJustify.CENTER
    }

    static ContentCol = {
      cols: 11,
      xl: 7
    }

    static Title = {
      isCentered: false,
      key: 'hiw_title',
      boldKey: 'hiw_title_bold'
    }

    static Space = {
      content: PresentationSpace.CLASSIC
    }

    static Text = {
      key: 'hiw_description',
      bold1Key: 'hiw_description_bold_1',
      bold2Key: 'hiw_description_bold_2',
      bold3Key: 'hiw_description_bold_3',
      bold4Key: 'hiw_description_bold_4',
      bold5Key: 'hiw_description_bold_5',
      bold6Key: 'hiw_description_bold_6',
      bold7Key: 'hiw_description_bold_7',
      bold8Key: 'hiw_description_bold_8',
      bold9Key: 'hiw_description_bold_9',
      styleColor: null
    }

    static AssetButton = {
      classes: 'mr-2 mb-2 ' + Color.textColor(Color.black),
      photos: 'hiw_show_photos',
      movies: 'hiw_show_movies'
    }
}

// MARK: - Class

export default class AppHiwViewModel {
  // MARK: - Properties

    /** @type {string} */
    _ref
    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {SectionTitleViewModel} */
    _title
    /** @type {string} */
    _text
    /** @type {SpaceViewModel} */
    _textBottomSpace
    /** @type {RowViewModel} */
    _assetItemRow
    /** @type {[TextButtonViewModel]} */
    _assetButtons

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._ref = PresentationNavigation.anchorKey(Constants.ref)

      this._contentRow = new RowViewModel()
      this._contentRow.justify = Constants.ContentRow.justify

      this._contentCol = new ColViewModel()
      this._contentCol.cols = Constants.ContentCol.cols
      this._contentCol.xl = Constants.ContentCol.xl

      this._title = new SectionTitleViewModel()
      this._title.setText(Constants.Title.key, Constants.Title.boldKey)
      this._title.isCentered = Constants.Title.isCentered

      let text = Localization.getText(Constants.Text.key)
      text = String.addHtmllineBreak(text)
      const textStyles = [
        new Style(Constants.Text.bold1Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold2Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold3Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold4Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold5Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold6Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold7Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold8Key, Constants.Text.styleColor),
        new Style(Constants.Text.bold9Key, Constants.Text.styleColor)
      ]
      this._text = Style.addStylesOnText(text, textStyles)

      this._textBottomSpace = new SpaceViewModel()
      this._textBottomSpace.space = Constants.Space.content

      this._assetItemRow = new RowViewModel()

      const assetsButtonTitles = [Constants.AssetButton.photos, Constants.AssetButton.movies]
      this._assetButtons = assetsButtonTitles.map(e => {
        const assetButton = new TextButtonViewModel()
        assetButton.title = Localization.getText(e)
        assetButton.classes = Constants.AssetButton.classes
        return assetButton
      })
    }

    // MARK: - Getter

    /** @return {string} */
    get ref () {
      return this._ref
    }

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {SectionTitleViewModel} */
    get title () {
      return this._title
    }

    /** @return {string} */
    get text () {
      return this._text
    }

    /** @return {SpaceViewModel} */
    get textBottomSpace () {
      return this._textBottomSpace
    }

    /** @return {RowViewModel} */
    get assetItemRow () {
      return this._assetItemRow
    }

    /** @return {[TextButtonViewModel]} */
    get assetButtons () {
      return this._assetButtons
    }
}
