import AppViewModel from '@/presentation/scene/app/view-model/appViewModel'
import PresentationNavigation from '@/presentation/enum/presentationNavigation'

// MARK: - Constants

class Constants {
  static scrollAfterTabsTapped = false
}

// MARK: - Class

export default class AppPresenter {
  // MARK: - Properties

  /** @type {AppViewModel} */
  _viewModel
  /** @type {any} */
  _refs

  /** @type {boolean} */
  _scrollAfterTabsTapped
  /** @type {any|null} */
  _scrollAfterTabsTappedTimeout

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} refs
   */
  constructor (refs) {
    this._viewModel = new AppViewModel()
    this._refs = refs
    this._scrollAfterTabsTapped = Constants.scrollAfterTabsTapped
    this._scrollAfterTabsTappedTimeout = null
  }

  // MARK: - Getter

  /** @return {AppViewModel} */
  get viewModel () {
    return this._viewModel
  }

  // MARK: - From Interactor

  /**
   * @param {boolean} show or hide navigation burger
   */
  showMenuFromInteractor (show) {
    this._viewModel.navigationMenu.showContent = show
  }

  updateSelectedNavigationItemFromInteractor () {
    if (this._scrollAfterTabsTapped) {
      return
    }

    const items = PresentationNavigation.all()
    let selectedIndex
    items.forEach(e => {
      const element = this._refs[PresentationNavigation.anchorKey(e)].$el
      const size = element.getBoundingClientRect()

      if (size.top < 0) {
        selectedIndex = e
      }
    })

    // Default selection
    if (!selectedIndex) {
      selectedIndex = PresentationNavigation.WIA
    }

    // Update tabs and navigation selected index
    this._updateAnchorSelectedIndex(selectedIndex)
  }

  /**
   * @param {string} anchor to scroll to correct div
   */
  scrollToAnchorFromInteractor (anchor) {
    // Scroll to div
    const element = this._refs[anchor].$el
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    })

    // Update tabs and navigation selected index
    this._updateAnchorSelectedIndex(PresentationNavigation.value(anchor))

    // Lock update tabs on scroll for X seconds
    this._scrollAfterTabsTapped = true
    const self = this

    // Clear timeout
    if (this._scrollAfterTabsTappedTimeout) {
      clearTimeout(this._scrollAfterTabsTappedTimeout)
    }

    // Unlock update
    this._scrollAfterTabsTappedTimeout = setTimeout(function () {
      self._scrollAfterTabsTapped = false
    }, 1000)
  }

  // MARK: - Other

  /**
   * Update tabs and navigation selected index
   * @param {int} value corresponding to the new selected index
   */
  _updateAnchorSelectedIndex (value) {
    this._viewModel.appBar.tabs.selectedIndex = value
    this._viewModel.navigationMenu.listSelectedIndex = value
  }
}
