import ImageViewModel from '@/presentation/view-model/image/imageViewModel'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
    static classes = String.empty

    static Image = {
      scr: 'img/logo/white.svg',
      style: 'height: 50vh;'
    }
}

// MARK: - Class

export default class AppLogoViewModel {
  // MARK: - Properties

    /** @type {string} */
    _classes
    /** @type {ImageViewModel} */
    _image

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._classes = Constants.classes
      this._image = new ImageViewModel()
      this._image.src = Constants.Image.scr
      this._image.style = Constants.Image.style
    }

    // MARK: - Getter

    /** @return {string} */
    get classes () {
      return this._classes
    }

    /** @return {ImageViewModel} */
    get image () {
      return this._image
    }
}
