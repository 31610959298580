import PresentationJustify from '@/presentation/enum/presentationJustify'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import Localization from '@/presentation/utils/localization'
import Style from '@/presentation/utils/style'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
    static ContentRow = {
      justify: PresentationJustify.CENTER
    }

    static ContentCol = {
      cols: 11,
      xl: 7
    }

    static Text = {
      key: 'catch_phrase_1',
      bold1Key: 'catch_phrase_1_style_1',
      bold2Key: 'catch_phrase_1_style_2',
      bold3Key: 'catch_phrase_1_style_3'
    }

    static textClasses = 'text-h5 text-center'
}

// MARK: - Class

export default class AppCatchPhraseViewModel {
  // MARK: - Properties

    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {string} */
    _text
    /** @type {string} */
    _textClasses

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._contentRow = new RowViewModel()
      this._contentRow.justify = Constants.ContentRow.justify

      this._contentCol = new ColViewModel()
      this._contentCol.cols = Constants.ContentCol.cols
      this._contentCol.xl = Constants.ContentCol.xl

      let text = Localization.getText(Constants.Text.key)
      text = String.addHtmllineBreak(text)
      const textStyles = [
        new Style(Constants.Text.bold1Key),
        new Style(Constants.Text.bold2Key),
        new Style(Constants.Text.bold3Key)
      ]
      this._text = Style.addStylesOnText(text, textStyles)

      this._textClasses = Constants.textClasses
    }

    // MARK: - Getter

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {string} */
    get text () {
      return this._text
    }

    /** @return {string} */
    get textClasses () {
      return this._textClasses
    }
}
