import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import PresentationJustify from '@/presentation/enum/presentationJustify'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import SectionTitleViewModel from '@/presentation/view-model/section-title/sectionTitleViewModel'
import PricesOffer from '@/presentation/scene/app/enum/appPricesOffer'

// MARK: - Constants

class Constants {
    static ref = PresentationNavigation.PRICES

    static ContentRow = {
      justify: PresentationJustify.CENTER
    }

    static ContentCol = {
      cols: 11,
      lg: 8
    }

    static ItemRow = {
      justify: PresentationJustify.CENTER
    }

    static ItemCol = {
      cols: 12,
      sm: 10,
      md: 6,
      lg: 6
    }

    static Title = {
      isCentered: false,
      key: 'prices_title',
      boldKey: 'prices_title_bold'
    }
}

// MARK: - Class

export default class AppPricesViewModel {
  // MARK: - Properties

    /** @type {string} */
    _ref
    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {SectionTitleViewModel} */
    _title
    /** @type {RowViewModel} */
    _itemRow
    /** @type {ColViewModel} */
    _itemCol
    /** @type {AppPricesOfferCardViewModel} */
    _offerCard

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._ref = PresentationNavigation.anchorKey(Constants.ref)

      this._contentRow = new RowViewModel()
      this._contentRow.justify = Constants.ContentRow.justify

      this._contentCol = new ColViewModel()
      this._contentCol.cols = Constants.ContentCol.cols
      this._contentCol.lgAndGreater = Constants.ContentCol.lg

      this._title = new SectionTitleViewModel()
      this._title.setText(Constants.Title.key, Constants.Title.boldKey)

      this._itemRow = new RowViewModel()
      this._itemRow.justify = Constants.ItemRow.justify

      this._itemCol = new ColViewModel()
      this._itemCol.cols = Constants.ItemCol.cols
      this._itemCol.smAndGreater = Constants.ItemCol.sm
      this._itemCol.mdAndGreater = Constants.ItemCol.md
      this._itemCol.lgAndGreater = Constants.ItemCol.lg

      this._offerCard = PricesOffer.card
    }

    // MARK: - Getter

    /** @return {string} */
    get ref () {
      return this._ref
    }

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {SectionTitleViewModel} */
    get title () {
      return this._title
    }

    /** @return {RowViewModel} */
    get itemRow () {
      return this._itemRow
    }

    /** @return {ColViewModel} */
    get itemCol () {
      return this._itemCol
    }

    /** @return {AppPricesOfferCardViewModel} */
    get offerCard () {
      return this._offerCard
    }
}
