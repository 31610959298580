<template>
  <!-- Content -->
  <custom-row :view-model="viewModel.contentRow">
    <custom-col :view-model="viewModel.contentCol">

      <!-- Title -->
      <custom-section-title :view-model="viewModel.title"/>

      <!-- Description -->
      <p>{{ viewModel.text }}</p>

      <!-- Description space -->
      <custom-space :view-model="viewModel.textBottomSpace"/>

      <!-- Link Buttons -->
      <custom-row :view-model="viewModel.linkItemRow">
        <div
            v-for="(item, i) in viewModel.linkButtons"
            :key="i"
        >
          <custom-button
              :view-model="item"
              @click="linkClicked(i)"
          />
        </div>
      </custom-row>
    </custom-col>
  </custom-row>
</template>

<script>
import AppContactViewModel from '@/presentation/scene/app/view-model/appContactViewModel'
import SectionTitleComponent from '@/app/common/component/section-title/SectionTitleComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import TextButtonComponent from '@/app/common/component/button/TextButtonComponent'
import { Events } from '@/presentation/main'

export default {
  name: 'AppContactComponent',

  components: {
    customSectionTitle: SectionTitleComponent,
    customSpace: SpaceComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customButton: TextButtonComponent
  },

  props: {
    viewModel: {
      type: AppContactViewModel,
      required: true
    }
  },

  methods: {
    linkClicked (index) {
      this.$emit(Events.contactLinkClicked, index)
    },

    sendClicked () {
      this.$emit(Events.contactSendClicked)
    }
  }
}
</script>
