import PresentationNavigation from '@/presentation/enum/presentationNavigation'
import PresentationJustify from '@/presentation/enum/presentationJustify'
import SectionTitleViewModel from '@/presentation/view-model/section-title/sectionTitleViewModel'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import Localization from '@/presentation/utils/localization'
import Style from '@/presentation/utils/style'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/enum/presentationSpace'
import String from '@/presentation/utils/string'
import TextButtonViewModel from '@/presentation/view-model/button/textButtonViewModel'
import Color from '@/presentation/utils/color'
import CardViewModel from '@/presentation/view-model/card/cardViewModel'
import AppLocalityPictures from '@/presentation/scene/app/enum/appLocalityPictures'

// MARK: - Constants

class Constants {
    static ref = PresentationNavigation.LOCALITY

    static StoreRow = {
      justify: PresentationJustify.CENTER
    }

    static ContentCol = {
      cols: 11,
      lg: 8,
      xl: 6
    }

    static Card = {
      isStyleForMobile: true,
      classes: 'pa-sm-12 ' + Color.black,
      mobileClasses: Color.grey
    }

    static Title = {
      isCentered: false,
      key: 'locality_title',
      boldKey: 'locality_title_bold'
    }

    static Space = {
      map: PresentationSpace.CLASSIC
    }

    static Text1 = {
      key: 'locality_address',
      bold1Key: 'locality_address_style_1',
      bold2Key: 'locality_address_style_2',
      bold3Key: 'locality_address_style_3'
    }

    static MapButton = {
      classes: 'mr-2 mb-2 ' + Color.textColor(Color.black),
      apple: 'locality_address_apple',
      google: 'locality_address_google'
    }

    static Text2 = {
      key: 'locality_other_information'
    }

    static PicturesContentRow = {
      justify: PresentationJustify.CENTER,
      classes: 'mt-12'
    }

    static PicturesContentCol = {
      cols: 11
    }

    static PicturesCol = {
      cols: 12,
      sm: 6,
      lg: 3
    }

    static pictureItems = AppLocalityPictures.all
}

// MARK: - Class

export default class AppLocalityViewModel {
  // MARK: - Properties

    /** @type {string} */
    _ref
    /** @type {RowViewModel} */
    _contentRow
    /** @type {ColViewModel} */
    _contentCol
    /** @type {CardViewModel} */
    _contentCard
    /** @type {SectionTitleViewModel} */
    _title
    /** @type {string} */
    _text1
    /** @type {RowViewModel} */
    _mapItemRow
    /** @type {[TextButtonViewModel]} */
    _mapButtons
    /** @type {SpaceViewModel} */
    _mapSpace
    /** @type {string} */
    _text2
    /** @type {RowViewModel} */
    _picturesContentRow
    /** @type {ColViewModel} */
    _picturesContentCol
    /** @type {RowViewModel} */
    _picturesRow
    /** @type {ColViewModel} */
    _picturesCol
    /** @type {[AppLocalityPictureCardViewModel]} */
    _picturesCard

    // MARK: - Constructor

    /**
     * @constructor
     */
    constructor () {
      this._ref = PresentationNavigation.anchorKey(Constants.ref)

      this._contentRow = new RowViewModel()
      this._contentRow.justify = Constants.StoreRow.justify

      this._contentCol = new ColViewModel()
      this._contentCol.cols = Constants.ContentCol.cols
      this._contentCol.lgAndGreater = Constants.ContentCol.lg
      this._contentCol.xl = Constants.ContentCol.xl

      this._contentCard = new CardViewModel()
      this._contentCard.isStyleForMobile = Constants.Card.isStyleForMobile
      this._contentCard.classes = Constants.Card.classes
      this._contentCard.mobileClasses = Constants.Card.mobileClasses

      this._title = new SectionTitleViewModel()
      this._title.setText(Constants.Title.key, Constants.Title.boldKey)
      this._title.isCentered = Constants.Title.isCentered

      let text1 = Localization.getText(Constants.Text1.key)
      text1 = String.addHtmllineBreak(text1)
      const text1Styles = [
        new Style(Constants.Text1.bold1Key),
        new Style(Constants.Text1.bold2Key),
        new Style(Constants.Text1.bold3Key)
      ]
      this._text1 = Style.addStylesOnText(text1, text1Styles)

      this._mapItemRow = new RowViewModel()

      const mapsButtonTitles = [Constants.MapButton.apple, Constants.MapButton.google]
      this._mapButtons = mapsButtonTitles.map(e => {
        const mapButton = new TextButtonViewModel()
        mapButton.title = Localization.getText(e)
        mapButton.classes = Constants.MapButton.classes
        return mapButton
      })

      this._mapSpace = new SpaceViewModel()
      this._mapSpace.space = Constants.Space.map

      this._text2 = String.addHtmllineBreak(Localization.getText(Constants.Text2.key))

      this._picturesContentRow = new RowViewModel()
      this._picturesContentRow.justify = Constants.PicturesContentRow.justify
      this._picturesContentRow.classes = Constants.PicturesContentRow.classes

      this._picturesContentCol = new ColViewModel()
      this._picturesContentCol.cols = Constants.PicturesContentCol.cols

      this._picturesRow = new RowViewModel()

      this._picturesCol = new ColViewModel()
      this._picturesCol.cols = Constants.PicturesCol.cols
      this._picturesCol.smAndGreater = Constants.PicturesCol.sm
      this._picturesCol.lgAndGreater = Constants.PicturesCol.lg

      this._picturesCard = Constants.pictureItems.map(e => {
        return AppLocalityPictures.card(e)
      })
    }

    // MARK: - Getter

    /** @return {string} */
    get ref () {
      return this._ref
    }

    /** @return {RowViewModel} */
    get contentRow () {
      return this._contentRow
    }

    /** @return {ColViewModel} */
    get contentCol () {
      return this._contentCol
    }

    /** @return {CardViewModel} */
    get contentCard () {
      return this._contentCard
    }

    /** @return {SectionTitleViewModel} */
    get title () {
      return this._title
    }

    /** @return {string} */
    get text1 () {
      return this._text1
    }

    /** @return {RowViewModel} */
    get mapItemRow () {
      return this._mapItemRow
    }

    /** @return {[TextButtonViewModel]} */
    get mapButtons () {
      return this._mapButtons
    }

    /** @return {SpaceViewModel} */
    get mapSpace () {
      return this._mapSpace
    }

    /** @return {string} */
    get text2 () {
      return this._text2
    }

    /** @return {RowViewModel} */
    get picturesContentRow () {
      return this._picturesContentRow
    }

    /** @return {ColViewModel} */
    get picturesContentCol () {
      return this._picturesContentCol
    }

    /** @return {RowViewModel} */
    get picturesRow () {
      return this._picturesRow
    }

    /** @return {ColViewModel} */
    get picturesCol () {
      return this._picturesCol
    }

    /** @return {[AppLocalityPictureCardViewModel]} */
    get picturesCard () {
      return this._picturesCard
    }
}
